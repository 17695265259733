import React from 'react';
import { useAmp } from 'next/amp';
import { useRouter } from 'next/router';
import Head from 'next/head';
import Link from 'next/link';
import styled from 'styled-components';
import { SimpleImg } from 'react-simple-img';

import { EpisodeProps } from '~/types';

const Wrap = styled.div`
  margin-bottom: 5rem;

  .episode__content {
    border: 1rem solid var(--purple);
    display: flex;
    flex-direction: row;
    padding: 1rem;
    align-items: center;

    @media (max-width: 600px) {
      flex-direction: column;
    }
  }

  &:nth-child(even) .episode__content {
    border-color: var(--green);
  }

  audio {
    width: 100%;
  }

  .episode__meta {
    align-self: center;
    flex: 1;
    color: var(--episode-meta);
    width: 100%;
  }

  a {
    color: CurrentColor;
    text-decoration: none;
  }

  .episode__date {
    text-align: right;
    margin-bottom: 1rem;
    color: #c1c1cb;
    font-size: 1.2rem;
  }

  .episode__notes {
    background: var(--purple);
    color: white;
    padding: 3.5rem 7.5rem;
    overflow: hidden;
    font-size: 1.6rem;

    @media (max-width: 550px) {
      padding: 1rem 3.5rem 2rem 3.5rem;
    }

    p:not(:first-of-type) {
      margin-top: 1rem;
    }
  }

  .episode__img--wrapper {
    width: 18rem;
    max-width: 18rem;
    height: 18rem;
    overflow: hidden;
    margin: 1rem 2.5rem 1rem 0;

    @media (max-width: 600px) {
      display: block;
      margin: 0 auto;
    }

    img {
      align-self: flex-start;
      object-fit: cover;
      border-radius: 50%;
    }
  }

  h1 {
    font-size: 3.4rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
  }

  h2 {
    font-size: 3.4rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
`;

interface Props extends EpisodeProps {
  style?: React.CSSProperties;
  shouldShowNotes?: boolean;
}

const makeDate = new Intl.DateTimeFormat('en', {
  month: 'long',
  day: 'numeric',
  year: 'numeric',
});

const makeNumber = new Intl.NumberFormat('en');

const Episode = ({
  date,
  image,
  imageAlt,
  link,
  episodeNumber,
  episodeTitle,
  audio,
  showNotes,
  shouldShowNotes,
}: Props) => {
  const isAmp = useAmp();
  const router = useRouter();
  const { id, ...query } = router.query;
  return (
    <>
      {isAmp && (
        <Head>
          <script
            async
            custom-element="amp-audio"
            src="https://cdn.ampproject.org/v0/amp-audio-0.1.js"
          />
        </Head>
      )}
      <Wrap>
        <div className="episode__date" data-testid="date">
          {makeDate.format(new Date(date))}
        </div>
        <div className="episode__content">
          <Link
            href={{
              pathname: '/episode/[id]',
              query: {
                ...query,
                id: episodeNumber,
              },
            }}
            as={{
              pathname: `/episode/${episodeNumber}`,
              query,
            }}
          >
            <a>
              <div className="episode__img--wrapper">
                {isAmp ? (
                  <amp-img
                    layout="responsive"
                    width={180}
                    height={180}
                    src={image}
                    alt={imageAlt}
                  />
                ) : (
                  <SimpleImg
                    width={180}
                    height={180}
                    src={image}
                    alt={imageAlt}
                  />
                )}
              </div>
            </a>
          </Link>
          <div className="episode__meta">
            <a href={link}>
              <h1>Episode — {makeNumber.format(episodeNumber)}</h1>
              <h2>{episodeTitle}</h2>
            </a>
            {isAmp ? (
              <amp-audio
                layout="fixed-height"
                width="auto"
                height={50}
                src={audio}
                artist="Trav and Los"
                artwork={image}
                preload="none"
              >
                <div>Your browser doesn’t support HTML5 audio</div>
              </amp-audio>
            ) : (
              <audio controls preload="none">
                <track kind="captions" />
                <source src={audio} />
                <div>Your browser doesn’t support HTML5 audio</div>
              </audio>
            )}
          </div>
        </div>
        {shouldShowNotes && showNotes && (
          <div className="episode__notes">
            {showNotes.split(/\n{2,}/g).map(sentence => (
              <p key={sentence}>{sentence}</p>
            ))}
          </div>
        )}
      </Wrap>
    </>
  );
};

export default Episode;
